import './App.css';
import React, {useEffect, useState} from 'react';
import AppInfo from "./components/AppInfo";
import AppStats from "./components/AppStats";
import InstallButton from "./components/InstallButton";
import Carousel from "./components/Carousel";
import AppDescription from "./components/AppDescription";
import AppRatings from "./components/AppRatings";
import CommentList from "./components/comment/CommentList";
import {useParams} from "react-router-dom";
import {ClipLoader} from 'react-spinners';
import InPagePush from "./components/push/InPagePush"; // импортируем спиннер

function App() {
    const {identifier} = useParams();
    const [appData, setAppData] = useState({
        appIconLink: '',
        name: '',
        developer: '',
        rating: '',
        downloadCount: '',
        size: '',
        numberOfRatings: '',
        carouselLinks: [],
        definition: '',
        numberOfComment: '',
        comments: [],
        pushMessages: []
    });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [manifest, setManifest] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`/api/v1/pwa/${identifier}`);
                if (response.ok) {
                    const data = await response.json();
                    console.log(data);
                    setAppData({
                        appIconLink: data.appIconLink,
                        name: data.name,
                        developer: data.developer,
                        rating: data.rating,
                        downloadCount: data.downloadCount,
                        size: data.size,
                        numberOfRatings: data.numberOfRatings,
                        carouselLinks: data.carouselLinks,
                        definition: data.definition,
                        numberOfComment: data.numberOfComment,
                        comments: data.comments,
                        pushMessages: data.pushMessages
                    });
                    document.title = data.name;
                } else {
                    throw new Error('Failed to fetch data');
                }
            } catch (error) {
                setError(error.message);
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        const fetchManifest = async () => {
            try {
                const manifestResponse = await fetch(`/api/v1/manifest/${identifier}`);
                if (manifestResponse.ok) {
                    console.log('start fetch manifest');
                    const manifestData = await manifestResponse.json();
                    setManifest(manifestData);
                    console.log('manifest successfully acquired');
                    console.log(manifestData);
                } else {
                    throw new Error('Failed to fetch manifest');
                }
            } catch (error) {
                setError(error.message);
                console.error(error);
            }
        };

        fetchData();
        fetchManifest();
    }, [identifier]);

    // Добавляем ссылку на манифест в head
    useEffect(() => {
        if (manifest) {
            const link = document.createElement('link');
            link.rel = 'manifest';
            link.href = `/api/v1/manifest/${identifier}`;  // Путь к манифесту
            document.head.appendChild(link);
        }
    }, [manifest, identifier]);  // Зависимость от manifest и identifier

    return (
        <div className="App">
            {
                loading ? (
                    <div className="loading">
                        <ClipLoader color="#00BFFF" loading={loading} size={150}/>
                    </div>
                ) : (
                    <div>
                        <AppInfo appIconLink={appData.appIconLink}
                                 name={appData.name}
                                 developer={appData.developer}/>
                        <AppStats rating={appData.rating}
                                  size={appData.size}
                                  downloadCount={appData.downloadCount}
                                  numberOfRatings={appData.numberOfRatings}/>
                        <InstallButton identifier={identifier}/>
                        <Carousel carouselLinks={appData.carouselLinks}/>
                        <AppDescription definition={appData.definition}/>
                        <AppRatings rating={appData.rating}
                                    numberOfComments={appData.numberOfComment}/>
                        <CommentList comments={appData.comments}/>
                        <InPagePush messages={appData.pushMessages} enabled={true}/>
                    </div>
                )
            }
        </div>
    );
}

export default App;