import React from 'react';
import './AppInfo.css';

const AppInfo = ({ appIconLink, name, developer }) => {
    return (
        <div className="app-info">
            <img src={appIconLink} alt="App Icon" className="app-icon" />
            <div className="app-details">
                <h1>{name}</h1>
                <a className="publisher">{developer}</a>
            </div>
        </div>
    );
};

export default AppInfo;