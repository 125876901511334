import React from 'react';
import './AppRatings.css';

const AppRatings = ({ rating, numberOfComments }) => {
    const averageRating = parseFloat(rating);

    // Динамическое распределение, с увеличенной долей 5-звездочных оценок
    const getRatingDistribution = (averageRating) => {
        const distribution = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 };

        // Более высокий процент 5-звездочных отзывов
        if (averageRating >= 4.5) {
            distribution[5] = 0.8;
            distribution[4] = 0.15;
            distribution[3] = 0.03;
            distribution[2] = 0.015;
            distribution[1] = 0.005;
        } else if (averageRating >= 3.5) {
            distribution[5] = 0.6;
            distribution[4] = 0.25;
            distribution[3] = 0.1;
            distribution[2] = 0.03;
            distribution[1] = 0.02;
        } else if (averageRating >= 2.5) {
            distribution[5] = 0.3;
            distribution[4] = 0.3;
            distribution[3] = 0.25;
            distribution[2] = 0.1;
            distribution[1] = 0.05;
        } else {
            distribution[5] = 0.15;
            distribution[4] = 0.25;
            distribution[3] = 0.3;
            distribution[2] = 0.2;
            distribution[1] = 0.1;
        }

        // Пропорции долей переводим в проценты
        return Object.keys(distribution)
            .map(score => ({
                score: parseInt(score, 10),
                width: distribution[score] * 100
            }))
            .reverse(); // Переворачиваем, чтобы 5 была сверху
    };

    const ratings = getRatingDistribution(averageRating);

    return (
        <div className="app-ratings">
            <h2 className="app-ratings-title">Оценки и отзывы</h2>

            {/* Шкала оценок */}
            <div className="ratings-content">
                <div className="rating-scales">
                    {ratings.map((scale, index) => (
                        <div key={index} className="scale-item">
                            <span>{scale.score}</span>
                            <div className="scale-bar">
                                <div
                                    className="scale-bar-filled"
                                    style={{ width: `${scale.width}%` }}
                                ></div>
                            </div>
                        </div>
                    ))}
                </div>

                {/* Информация о рейтинге */}
                <div className="rating-summary">
                    <div className="rating-info">
                        <span className="rating-star">⭐</span>
                        <div className="rating-number">
                            {averageRating.toFixed(1)}
                        </div>
                    </div>
                    <div className="rating-details">
                        <span>{numberOfComments} отзывов</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppRatings;
