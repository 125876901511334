import React, { useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const InPagePush = ({ messages, enabled }) => {
    useEffect(() => {
        if (!enabled || messages == null || messages.length === 0) return;

        let showCount = 0;
        const firstTimeout = setTimeout(() => {
            if (showCount < messages.length) {
                toast.info(messages[showCount], {
                    position: "bottom-center",
                    autoClose: 5000, // Уведомление исчезает через 5 секунд
                    pauseOnHover: true,
                    draggable: true,
                    icon: false, // Убираем значок
                    onClose: () => {
                        showCount++;
                    }
                });
            }
        }, 10000); // Появление первого пуша через 10 секунд

        // Интервал для последующих сообщений каждые 20 секунд
        const interval = setInterval(() => {
            if (showCount < messages.length) {
                toast.info(messages[showCount], {
                    position: "bottom-center",
                    autoClose: 5000,
                    pauseOnHover: true,
                    draggable: true,
                    icon: false, // Убираем значок
                    onClose: () => {
                        showCount++;
                    }
                });
            }
        }, 20000); // Появление последующих пушей каждые 20 секунд

        return () => {
            clearTimeout(firstTimeout);
            clearInterval(interval);
        };
    }, [enabled, messages]);

    return (
        <>
            <ToastContainer />
        </>
    );
};

export default InPagePush;
