import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from "./App";
import {BrowserRouter, Route, Routes} from "react-router-dom";

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')  // Убедитесь, что путь правильный
            .then((registration) => {
                console.log('Service Worker зарегистрирован с областью:', registration.scope);
            })
            .catch((error) => {
                console.log('Ошибка при регистрации Service Worker:', error);
            });
    });
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <BrowserRouter>
        <Routes>
            <Route path="/app/:identifier" element={<App />} />
        </Routes>
    </BrowserRouter>
);