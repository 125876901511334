import React, { useState } from 'react';
import './Comment.css';

const Comment = ({ author, date, text, initialLikes = 0, initialDislikes = 0, rating }) => {
    const [likes, setLikes] = useState(initialLikes);
    const [dislikes, setDislikes] = useState(initialDislikes);
    const [userAction, setUserAction] = useState(null); // 'like', 'dislike', or null

    const handleLike = () => {
        if (userAction === 'like') {
            // Отменить лайк
            setLikes(likes - 1);
            setUserAction(null);
        } else {
            // Поставить лайк и убрать дизлайк, если был
            setLikes(likes + 1);
            if (userAction === 'dislike') setDislikes(dislikes - 1);
            setUserAction('like');
        }
    };

    const handleDislike = () => {
        if (userAction === 'dislike') {
            // Отменить дизлайк
            setDislikes(dislikes - 1);
            setUserAction(null);
        } else {
            // Поставить дизлайк и убрать лайк, если был
            setDislikes(dislikes + 1);
            if (userAction === 'like') setLikes(likes - 1);
            setUserAction('dislike');
        }
    };

    return (
        <div className="comment">
            <div className="comment-header">
                <strong>{author}</strong>
                <span className="comment-date">{date}</span>
                <span className="comment-rating">{"★".repeat(rating)}</span>
            </div>
            <p className="comment-text">{text}</p>
            <div className="comment-actions">
                <span onClick={handleLike} style={{ cursor: 'pointer', color: userAction === 'like' ? '#007acc' : '#666' }}>
                    👍 {likes}
                </span>
                <span onClick={handleDislike} style={{ cursor: 'pointer', color: userAction === 'dislike' ? '#007acc' : '#666' }}>
                    👎 {dislikes}
                </span>
            </div>
        </div>
    );
};

export default Comment;
