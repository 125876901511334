import React, { useState } from "react";
import Comment from "./Comment";
import './Comment.css';

const CommentsList = ({ comments }) => {
    const [visibleComments, setVisibleComments] = useState(3); // Состояние для отслеживания видимых комментариев

    // Функция для обработки нажатия на кнопку "Показать еще"
    const handleShowMore = () => {
        setVisibleComments(visibleComments + 3); // Увеличиваем количество видимых комментариев на 3
    };

    return (
        <div className="comments-list">
            {/* Отображаем комментарии с ограничением по видимости */}
            {comments.slice(0, visibleComments).map((comment, index) => (
                <Comment key={index} {...comment} />
            ))}

            {/* Кнопка "Показать еще", которая появляется, если есть больше комментариев */}
            {comments.length > visibleComments && (
                <button className="show-more-button" onClick={handleShowMore}>
                    Показать еще
                </button>
            )}
        </div>
    );
};

export default CommentsList;
