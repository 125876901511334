import React, {useEffect, useState} from "react";
import "./InstallButton.css";

const InstallButton = ({ textInstall = "Установить", identifier = "" }) => {
    const [supportsPWA, setSupportsPWA] = useState(false); // Поддержка PWA
    const [promptInstall, setPromptInstall] = useState(null); // Для отсроченного запроса на установку
    const [isAppInstalled, setIsAppInstalled] = useState(false); // Флаг для установки приложения
    const [publicKey, setPublicKey] = useState(""); // VAPID Public Key

    useEffect(() => {
        const handler = (e) => {
            e.preventDefault();
            setSupportsPWA(true);
            setPromptInstall(e); // Сохраняем событие для дальнейшего использования
        };

        window.addEventListener("beforeinstallprompt", handler);

        const checkIfInstalled = () => {
            if (
                window.matchMedia("(display-mode: standalone)").matches ||
                window.navigator.standalone === true
            ) {
                setIsAppInstalled(true);
            } else {
                setIsAppInstalled(false);
            }
        };

        checkIfInstalled();

        window.addEventListener("appinstalled", checkIfInstalled); // Проверяем состояние после установки

        // Запрашиваем публичный VAPID ключ с сервера
        fetch("/api/v1/push/key")
            .then((response) => response.text())
            .then((key) => {
                console.log("Полученный VAPID ключ:", key);
                setPublicKey(key);
            })
            .catch((err) => console.error("Не удалось получить VAPID ключ:", err));

        return () => {
            window.removeEventListener("beforeinstallprompt", handler);
            window.removeEventListener("appinstalled", checkIfInstalled);
        };
    }, [identifier]);

    const prepareSubscriptionPayload = (subscription) => {
        return {
            notificationEndPoint: subscription.endpoint,
            publicKey: subscription.getKey('p256dh') ? btoa(String.fromCharCode(...new Uint8Array(subscription.getKey('p256dh')))) : null,
            auth: subscription.getKey('auth') ? btoa(String.fromCharCode(...new Uint8Array(subscription.getKey('auth')))) : null,
        };
    };

    const subscribeToPushNotifications = async () => {
        try {
            const registration = await navigator.serviceWorker.ready;

            // Запрашиваем разрешение на уведомления
            const permission = await Notification.requestPermission();
            if (permission !== "granted") {
                console.log("Разрешение на уведомления отклонено.");
                return;
            }

            // Создаем подписку для Push API
            const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicKey),
            });

            const payload = prepareSubscriptionPayload(subscription);

            console.log('start sent ' + payload);
            // Отправляем подписку на сервер
            let response = await fetch('/api/v1/push/subscribe', {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
            });

            console.log("Подписка успешно отправлена на сервер.");
            console.log(response.text());
        } catch (err) {
            console.error("Ошибка подписки на уведомления:", err);
        }
    };

    const onClick = (evt) => {
        evt.preventDefault();

        if (supportsPWA && promptInstall && !isAppInstalled) {
            promptInstall.prompt(); // Показываем установочный баннер
            promptInstall.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("Пользователь установил PWA!");
                    setIsAppInstalled(true);
                    subscribeToPushNotifications(); // Подписываем на уведомления
                } else {
                    console.log("Пользователь отклонил установку.");
                }
                setPromptInstall(null);
            });
        } else if (!supportsPWA) {
            // Если PWA не поддерживается, перенаправляем на нужный URL
            window.location.href = `/api/v1/redirect/${identifier}`;
        }
    };

    // Конвертация VAPID ключа из Base64
    const urlBase64ToUint8Array = (base64String) => {
        if (!base64String || typeof base64String !== "string") {
            throw new Error("Некорректный формат VAPID ключа: " + base64String);
        }
        const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
        const base64 = (base64String + padding).replace(/-/g, "+").replace(/_/g, "/");
        try {
            const rawData = window.atob(base64); // Декодируем Base64
            return Uint8Array.from([...rawData].map((char) => char.charCodeAt(0)));
        } catch (e) {
            throw new Error("Ошибка декодирования Base64 строки: " + e.message);
        }
    };

    return (
        supportsPWA && (
            <button className="install_button" onClick={onClick}>
                {textInstall}
            </button>
        )
    );
};

export default InstallButton;
