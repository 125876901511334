import React, { useState } from 'react';
import './AppDescription.css';

const AppDescription = ({definition}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="app-description-container">
            <h2 className="app-description-title">Описание приложения</h2>
            <p className={`app-description-text ${isExpanded ? 'expanded' : ''}`}>
                {definition}
            </p>
            <button className="expand-button" onClick={toggleDescription}>
                {isExpanded ? 'Скрыть' : 'Читать подробнее'}
            </button>
        </div>
    );
};

export default AppDescription;
