import React from 'react';
import './AppStats.css';
import {FaStar} from 'react-icons/fa';

const AppStats = ({rating, downloadCount, size, numberOfRatings}) => {
    return (
        <div className="app-stats">
            <div className="stat-item">
                <FaStar className="star-icon"/>
                <span className="stat-value">{rating}</span>
                <span className="stat-label">{numberOfRatings} оценок</span>
            </div>
            <div className="stat-item">
                <span className="stat-value">{downloadCount}</span>
                <span className="stat-label">Скачиваний</span>
            </div>
            <div className="stat-item">
                <span className="stat-value">{size} МБ</span>
                <span className="stat-label">Размер</span>
            </div>
            <div className="stat-item">
                <span className="stat-value">18+</span>
                <span className="stat-label">Возраст</span>
            </div>
        </div>
    );
};

export default AppStats;
