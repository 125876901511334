import React, { useRef, useState } from 'react';
import './Carousel.css';

const Carousel = ({ carouselLinks }) => {
    const [activeImage, setActiveImage] = useState(null);
    const [touchStartX, setTouchStartX] = useState(0);
    const carouselRef = useRef(null);

    const handleTouchStart = (e) => {
        const touchStart = e.touches[0].clientX;
        setTouchStartX(touchStart);
        e.preventDefault(); // предотвращаем выделение текста
    };

    const handleTouchMove = (e) => {
        e.preventDefault(); // предотвращаем выделение текста
    };

    const handleTouchEnd = (e) => {
        const touchEndX = e.changedTouches[0].clientX;
        const swipeDistance = touchStartX - touchEndX;

        if (swipeDistance > 50) {
            carouselRef.current.scrollBy({
                left: carouselRef.current.offsetWidth,
                behavior: 'smooth'
            });
        } else if (swipeDistance < -50) {
            carouselRef.current.scrollBy({
                left: -carouselRef.current.offsetWidth,
                behavior: 'smooth'
            });
        }
    };

    const openImage = (index) => {
        setActiveImage(index);
    };

    const closeImage = () => {
        setActiveImage(null);
    };

    // Запрещаем выделение при клике или касании
    const preventTextSelection = (e) => {
        e.preventDefault(); // блокирует выделение текста при нажатии
    };

    return (
        carouselLinks.length > 0 && (
            <div className="carousel-wrapper">
                <div
                    className="carousel-panel"
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
                    <div
                        className="carousel-container"
                        ref={carouselRef}
                    >
                        {carouselLinks.map((image, index) => (
                            <div
                                key={index}
                                className="carousel-item"
                                onClick={() => openImage(index)}
                                onMouseDown={preventTextSelection} // предотвращаем выделение текста
                                onTouchStart={preventTextSelection} // предотвращаем выделение на мобильных
                            >
                                <img
                                    src={image}
                                    alt={`Screenshot ${index + 1}`}
                                    className="carousel-image"
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {activeImage !== null && (
                    <div className="image-overlay" onClick={closeImage}>
                        <img
                            src={carouselLinks[activeImage]}
                            alt="Enlarged"
                            className="enlarged-image"
                        />
                    </div>
                )}
            </div>
        )
    );
};

export default Carousel;
